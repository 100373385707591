import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment";

import Loader from "../Loader";

import "./style.sass";

import { fetchData } from "./../../redux";

function Schedule({ schedule = { values: [], breakPoints: [] } as any, fetchData = undefined as any, pure = false as boolean }) {
	// if data were not loaded in App, load them separately
	useEffect(() => {
		if (!schedule?.values?.length && !schedule?.breakPoints?.length) fetchData("schedule");
	}, [fetchData, schedule]);

	// just props values for easier usage
	const { values, breakPoints } = schedule;

	// active time period
	const [active, setActive] = useState<any>([]);

	// holds current value
	const time = moment(moment(), "HH:mm");
	const current = values?.[moment().day() - 1]?.map(
		(day = undefined as any) =>
			day.filter(({ strtTm, endTm } = undefined as any) => time.isBetween(moment(strtTm, "HH:mm"), moment(endTm, "HH:mm")))?.[0]
	)?.[0];

	// needed variables
	const days = ["po", "út", "st", "čt", "pá", "so", "ne"];
	let rowCnt = 2 as number;

	// for height of time befores
	const rowCntMax =
		values
			?.map((day = undefined as any) => day.length)
			?.reduce((total = 0 as number, currentValue = 0 as number) => (total += currentValue), 0) + 1;

	const render = () => {
		return (
			<Loader
				loaded={Object.keys(schedule).length}
				cover={false}>
				<div
					id="schedule-table"
					style={{
						gridTemplateColumns: `repeat(${breakPoints?.[breakPoints.length - 1]?.begin}, 1fr)`,
					}}>
					{breakPoints?.map(({ begin, time } = undefined as any, key = 0 as number) => (
						<React.Fragment key={key}>
							<span
								className={cx("schedule-breakPoints-line", {
									active: active?.includes(time),
									current: time === current?.strtTm || time === current?.endTm,
								})}
								style={{
									gridColumn: begin + 2,
									gridRow: `1 / span ${rowCntMax}`,
								}}></span>
							<span
								className={cx("schedule-breakPoints", {
									active: active?.includes(time),
									current: time === current?.strtTm || time === current?.endTm,
								})}
								style={{
									gridColumn: begin + 2,
								}}>
								{time}
							</span>
						</React.Fragment>
					))}
					{values?.map((day = undefined as any, dayKey = 0 as number) => {
						// has to be stored before returning, because rowCnt is incremented after current day is created
						const result = (
							<React.Fragment key={dayKey}>
								<div
									className="schedule-day-line"
									style={{
										gridRow: rowCnt,
									}}></div>
								<span
									className="schedule-day-text"
									style={{
										gridRow: rowCnt,
									}}>
									{days[dayKey]}
								</span>
								{day.map((row = undefined as any, rowKey = 0 as number) =>
									row.map(({ text, strtTm, endTm, begin, grow } = undefined as any, valueKey = 0 as number) => (
										<span
											className={cx("schedule-object", {
												current:
													dayKey === moment().day() - 1 && strtTm === current?.strtTm && endTm === current?.endTm,
											})}
											key={valueKey}
											style={{
												gridRow: rowCnt + rowKey,
												gridColumnStart: begin + 2,
												gridColumnEnd: `span ${grow}`,
											}}
											onClick={() => {
												setActive([strtTm, endTm]);
											}}
											onMouseOver={() => {
												setActive([strtTm, endTm]);
											}}
											onMouseLeave={() => {
												setActive([]);
											}}>
											{text}
										</span>
									))
								)}
							</React.Fragment>
						);
						rowCnt += day.length;
						return result;
					})}
				</div>
			</Loader>
		);
	};

	if (pure) return render();
	return (
		<div id="schedule">
			<h2>rozvrh tělocvičny</h2>
			{render()}
		</div>
	);
}

const mapStateToProps = (state: any) => {
	return {
		schedule: state.data.schedule,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		fetchData: (target = "" as string) => dispatch(fetchData(target)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
